import circle from './logo-circle.svg';
import text from './logo-text.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="App-logo-container">
          <img src={circle} className="App-logo inner" alt="logo" />
          <img src={text} className="App-logo-text inner" alt="logo text" />
        </div>
      </header>
      <p className="App-overview">
        Carpe Works is a subsidiary of Democracy Guide, 
        established to create a trade association for 
        drivers in the Columbus Ohio metro area.
        <br/><br/>        
        The primary purpose of this association is the 
        Electric Vehicle Exchange (EVE) program.
        <br/><br/>
        We are currently seeking active transportation 
        network platform drivers (Uber, Lyft, DoorDash, etc) 
        interested in sharing access to an EV for professional usage
        to participate in our pilot study.
        <br/><br/>
        Pariticpants in the study will be able to reserve our Polestar 2
        for shifts of up to five hours for professional driving.
        <br/><br/>
        The program provides insurance coverage for the drivers in the 
        pilot study as well as covering fuel, standard cleaning and 
        maintenance costs on the vehicle.
        <br/><br/>
        The car comes equipped with two thermal bags in the hatch,
        a phone mount, cabin and front facing dashcams, sanitizer,
        face masks and general cleaning supplies.
        <br/><br/>
        If you are interested in participating in our pilot,
        please fill out the <a href="https://forms.gle/JP3b5icQZ8TZTqRz6">sign up form here</a>.
        <br/><br/>
        We meet weekly at the Franklinton Library in Columbus: <a href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NGJybWIzZGg1djV0dTFqb2dnb2p0aHFoMzhfMjAyMjA0MTFUMjIwMDAwWiBjX3RoMGU3NGVwNGFvbWxoc2Y3Z3JhYXZpMHM0QGc&tmsrc=c_th0e74ep4aomlhsf7graavi0s4%40group.calendar.google.com&scp=ALL">calendar link</a>, <a href="https://www.google.com/maps/search/1061+W+Town+St+Columbus,+OH++43222+United+States?source=mc">google maps link</a>
      </p>
      <p className="App-overview">
      </p>  
    </div>
  );
}

export default App;
